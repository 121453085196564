/*------- 14. Brand logo style  -------*/

.brand-logo-active,
.brand-logo-active-2 {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
      }
    }
  }
  .single-brand-logo,
  .single-brand-logo2 {
    text-align: center;
    img {
      height: 130px;
      width: 130px;
      border-radius: 50%;
      // filter: grayscale(100%);
      transition: all 0.5s ease 0s;
    }
    &:hover img {
      // filter: grayscale(0%);
    }
  }
}

.brand-logo-wrap {
  padding: 100px 90px;
  @media #{$xs-layout} {
    padding: 50px 20px;
  }
}
